<template>
	<div>
		<iframe :src="url"></iframe>
	</div>
</template>

<script>
    export default {
        name: "DevDocManage",
		data: function () {
			return {
				url: ""
			}
		},
		mounted() {
			this.url = this.$route.params.url;
		}
	}
</script>

<style scoped>

</style>
